 <template>
    <div>
         <a-button class="ant-btn-danger" @click="loginoutBtn"  type="primary">
              {{$t('setting.btn_1')}}<a-icon type="logout" />
          </a-button>

    </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
    name: 'NodeprojectSetting',
    components: {  },
    directives: {  },
    data() {
        return {
            
        };
    },
    mounted() {
        
    },
    methods: {
        loginoutBtn(){
            Cookies.set("maker_id","")
            this.$router.replace("/login")
        }
    },
};
</script>

<style  scoped>

</style>